const defaultGroceryStores = [
    {
        "id": 1704610962275,
        "name": "BJ's Wholesale Club",
        "address": "6944 W. 130th St.",
        "zip": 44130,
        "items": [
            {
                "id": 1704646846770,
                "type": "Organic EVOO",
                "brand": "Wellsley Farms",
                "cost": 17.49,
                "size": 2,
                "sizeType": "L",
                "parentId": 1704610962275,
                "pricePerOz": 0.26,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704646902445,
                "type": "Organic Broccoli Florets (frozen)",
                "brand": "Wellsley Farms",
                "cost": 8.99,
                "size": 4,
                "sizeType": "lb",
                "parentId": 1704610962275,
                "pricePerOz": 0.14,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704647020773,
                "type": "Organic Honey",
                "brand": "Wellsley Farms",
                "cost": 15.99,
                "size": 48,
                "sizeType": "oz",
                "parentId": 1704610962275,
                "pricePerOz": 0.33,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704647304554,
                "type": "Organic Sweet Potatoes",
                "brand": "Farmpak",
                "cost": 3.99,
                "size": 3,
                "sizeType": "lb",
                "parentId": 1704610962275,
                "pricePerOz": 0.08,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704647388934,
                "type": "Organic Butter",
                "brand": "Wellsley Farms",
                "cost": 11.99,
                "size": 2,
                "sizeType": "lb",
                "parentId": 1704610962275,
                "pricePerOz": 0.37,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704647694814,
                "type": "Organic Pine Nuts",
                "brand": "Wellsley Farms",
                "cost": 14.49,
                "size": 8,
                "sizeType": "oz",
                "parentId": 1704610962275,
                "pricePerOz": 1.81,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704647761661,
                "type": "Organic Eggs",
                "brand": "Wellsley Farms",
                "cost": 7.29,
                "size": 18,
                "sizeType": "unit",
                "parentId": 1704610962275,
                "pricePerOz": 0.41,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704647787522,
                "type": "Free Range Eggs",
                "brand": "Wellsley Farms",
                "cost": 7.49,
                "size": 24,
                "sizeType": "unit",
                "parentId": 1704610962275,
                "pricePerOz": 0.31,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704647825847,
                "type": "Uncured Bacon",
                "brand": "Applegate",
                "cost": 15.99,
                "size": 48,
                "sizeType": "oz",
                "parentId": 1704610962275,
                "pricePerOz": 0.33,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704647847056,
                "type": "Uncured Turkey Bacon",
                "brand": "Applegate",
                "cost": 13.99,
                "size": 48,
                "sizeType": "oz",
                "parentId": 1704610962275,
                "pricePerOz": 0.29,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704647907470,
                "type": "Organic Grass-Fed Sirloin Steak",
                "brand": "Verde Farms",
                "cost": 16.99,
                "size": 18,
                "sizeType": "oz",
                "parentId": 1704610962275,
                "pricePerOz": 0.94,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704647947846,
                "type": "Organic Grass-Fed Ground Beef 85/15",
                "brand": "Verde Farms",
                "cost": 20.99,
                "size": 3,
                "sizeType": "lb",
                "parentId": 1704610962275,
                "pricePerOz": 0.44,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704647976241,
                "type": "Feta Cheese",
                "brand": "Epiros",
                "cost": 7.99,
                "size": 14.1,
                "sizeType": "oz",
                "parentId": 1704610962275,
                "pricePerOz": 0.57,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704648026635,
                "type": "Organic Onion",
                "brand": "Wellsley Farms",
                "cost": 3.99,
                "size": 3,
                "sizeType": "lb",
                "parentId": 1704610962275,
                "pricePerOz": 0.08,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704648048324,
                "type": "Organic Cane Sugar",
                "brand": "Wellsley Farms",
                "cost": 8.99,
                "size": 10,
                "sizeType": "lb",
                "parentId": 1704610962275,
                "pricePerOz": 0.06,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704648094791,
                "type": "Organic Kombucha Trilogy",
                "brand": "GT's Synergy",
                "cost": 7.99,
                "size": 48,
                "sizeType": "oz",
                "parentId": 1704610962275,
                "pricePerOz": 0.17,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704648189262,
                "type": "Organic Chicken Breast",
                "brand": "Harvestland",
                "cost": 14.98,
                "size": 2.5,
                "sizeType": "lb",
                "parentId": 1704610962275,
                "pricePerOz": 0.37,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704648307165,
                "type": "Sharp Cheddar Cheese",
                "brand": "Wellsley Farms",
                "cost": 5.99,
                "size": 32,
                "sizeType": "oz",
                "parentId": 1704610962275,
                "pricePerOz": 0.19,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704654433401,
                "type": "Organic Whole Milk Yogurt (plain)",
                "brand": "Stonyfield Organic",
                "cost": 3.99,
                "size": 32,
                "sizeType": "oz",
                "parentId": 1704610962275,
                "pricePerOz": 0.12,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704947168401,
                "type": "Organic Spinach",
                "brand": "Wellsley Farms",
                "cost": 4.29,
                "size": 16,
                "sizeType": "oz",
                "parentId": 1704610962275,
                "pricePerOz": 0.268125,
                "dateAdded": "1/10/2024"
            },
            {
                "id": 1705027906377,
                "type": "Organic Garlic",
                "brand": "Spice World",
                "cost": 2.99,
                "size": 5,
                "sizeType": "unit",
                "parentId": 1704610962275,
                "pricePerOz": 0.5980000000000001,
                "dateAdded": "1/11/2024"
            },
            {
                "id": 1705027953565,
                "type": "Organic Romaine Lettuce Hearts",
                "brand": "Foxy",
                "cost": 3.29,
                "size": 3,
                "sizeType": "unit",
                "parentId": 1704610962275,
                "pricePerOz": 1.0966666666666667,
                "dateAdded": "1/11/2024"
            },
            {
                "id": 1705027989526,
                "type": "Feta Cheese (chunk)",
                "brand": "Nikis",
                "cost": 11.99,
                "size": 2.5,
                "sizeType": "lb",
                "parentId": 1704610962275,
                "pricePerOz": 0.29975,
                "dateAdded": "1/11/2024"
            },
            {
                "id": 1705028027980,
                "type": "Organic Baby Cut Carrots",
                "brand": "Wellsley Farms",
                "cost": 3.29,
                "size": 2,
                "sizeType": "lb",
                "parentId": 1704610962275,
                "pricePerOz": 0.1028125,
                "dateAdded": "1/11/2024"
            },
            {
                "id": 1705028073543,
                "type": "Organic Uncured Beef Hot Dogs",
                "brand": "Applegate",
                "cost": 11.99,
                "size": 20,
                "sizeType": "oz",
                "parentId": 1704610962275,
                "pricePerOz": 0.5995,
                "dateAdded": "1/11/2024"
            },
            {
                "id": 1705869637512,
                "type": "Organic Baby Bella Mushrooms",
                "brand": "Wellsley Farms",
                "cost": 5.99,
                "size": 24,
                "sizeType": "oz",
                "parentId": 1704610962275,
                "pricePerOz": 0.24958333333333335,
                "dateAdded": "1/21/2024"
            },
            {
                "id": 1705869785041,
                "type": "Organic Green Beans",
                "brand": "Pero",
                "cost": 5.99,
                "size": 24,
                "sizeType": "oz",
                "parentId": 1704610962275,
                "pricePerOz": 0.24958333333333335,
                "dateAdded": "1/21/2024"
            },
            {
                "id": 1706416161642,
                "type": "Organic Milk (whole)",
                "brand": "Wellsley Farms",
                "cost": 12.79,
                "size": 192,
                "sizeType": "oz",
                "parentId": 1704610962275,
                "pricePerOz": 0.06661458333333332,
                "dateAdded": "1/27/2024"
            }
        ],
        "pageSize": 10
    },
    {
        "id": 1704611159221,
        "name": "Walmart Supercenter",
        "address": "8303 W Ridgewood Dr",
        "zip": 44129,
        "items": [
            {
                "id": 1704648401501,
                "type": "Organic lemons",
                "brand": "Great Value",
                "cost": 6,
                "size": 2,
                "sizeType": "lb",
                "parentId": 1704611159221,
                "pricePerOz": 0.19,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704648493506,
                "type": "Organic Chicken Broth",
                "brand": "Great Value",
                "cost": 2.18,
                "size": 32,
                "sizeType": "oz",
                "parentId": 1704611159221,
                "pricePerOz": 0.07,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704648514213,
                "type": "Organic Chicken Broth (low sodium)",
                "brand": "Great Value",
                "cost": 2.18,
                "size": 32,
                "sizeType": "oz",
                "parentId": 1704611159221,
                "pricePerOz": 0.07,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704648620185,
                "type": "Organic Bell Pepper (color)",
                "brand": "",
                "cost": 3.46,
                "size": 2,
                "sizeType": "unit",
                "parentId": 1704611159221,
                "pricePerOz": 1.73,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704648635491,
                "type": "Organic Bell Pepper (green)",
                "brand": "",
                "cost": 2.96,
                "size": 2,
                "sizeType": "unit",
                "parentId": 1704611159221,
                "pricePerOz": 1.48,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704648749384,
                "type": "Organic Milk (whole)",
                "brand": "Organic Valley",
                "cost": 7.96,
                "size": 128,
                "sizeType": "oz",
                "parentId": 1704611159221,
                "pricePerOz": 0.06,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704648807678,
                "type": "Organic Milk (whole)",
                "brand": "Maple Hill Creamery",
                "cost": 5.88,
                "size": 64,
                "sizeType": "oz",
                "parentId": 1704611159221,
                "pricePerOz": 0.09,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704648856191,
                "type": "Organic White Rice",
                "brand": "Carolina",
                "cost": 3.68,
                "size": 2,
                "sizeType": "lb",
                "parentId": 1704611159221,
                "pricePerOz": 0.12,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704648895328,
                "type": "Organic Tomatoes (beefsteak)",
                "brand": "",
                "cost": 2.46,
                "size": 2,
                "sizeType": "unit",
                "parentId": 1704611159221,
                "pricePerOz": 1.23,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704648931572,
                "type": "Organic Tomatoes (grape)",
                "brand": "",
                "cost": 2.83,
                "size": 10,
                "sizeType": "oz",
                "parentId": 1704611159221,
                "pricePerOz": 0.28,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704648964581,
                "type": "Organic Olive Oil (extra light)",
                "brand": "Bertolli",
                "cost": 9.98,
                "size": 16.9,
                "sizeType": "oz",
                "parentId": 1704611159221,
                "pricePerOz": 0.59,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704648993890,
                "type": "Organic EVOO",
                "brand": "Great Value",
                "cost": 5.97,
                "size": 17,
                "sizeType": "oz",
                "parentId": 1704611159221,
                "pricePerOz": 0.35,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704649013495,
                "type": "Organic EVOO",
                "brand": "Great Value",
                "cost": 14.68,
                "size": 51,
                "sizeType": "oz",
                "parentId": 1704611159221,
                "pricePerOz": 0.29,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704649038793,
                "type": "Organic EVOO",
                "brand": "Carapelli",
                "cost": 9.98,
                "size": 16.9,
                "sizeType": "oz",
                "parentId": 1704611159221,
                "pricePerOz": 0.59,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704649065658,
                "type": "Organic EVOO (unfiltered)",
                "brand": "Carapelli",
                "cost": 9.98,
                "size": 16.9,
                "sizeType": "oz",
                "parentId": 1704611159221,
                "pricePerOz": 0.59,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704649119260,
                "type": "Organic Cabbage",
                "brand": "",
                "cost": 4.15,
                "size": 1,
                "sizeType": "unit",
                "parentId": 1704611159221,
                "pricePerOz": 4.15,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704654057024,
                "type": "Mason Jars (16oz)",
                "brand": "Ball",
                "cost": 13.44,
                "size": 12,
                "sizeType": "unit",
                "parentId": 1704611159221,
                "pricePerOz": 1.12,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704654081912,
                "type": "Mason Jars (32oz wide)",
                "brand": "Ball",
                "cost": 16.44,
                "size": 12,
                "sizeType": "unit",
                "parentId": 1704611159221,
                "pricePerOz": 1.37,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704654115446,
                "type": "Mason Jars (16oz wide)",
                "brand": "Ball",
                "cost": 15.44,
                "size": 12,
                "sizeType": "unit",
                "parentId": 1704611159221,
                "pricePerOz": 1.29,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704654143691,
                "type": "Mason Jars (64oz wide)",
                "brand": "Ball",
                "cost": 16.44,
                "size": 6,
                "sizeType": "unit",
                "parentId": 1704611159221,
                "pricePerOz": 2.74,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704654168888,
                "type": "Mason Jars (32oz)",
                "brand": "Ball",
                "cost": 15.44,
                "size": 12,
                "sizeType": "unit",
                "parentId": 1704611159221,
                "pricePerOz": 1.29,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704654187149,
                "type": "Mason Jars (8oz)",
                "brand": "Ball",
                "cost": 13.44,
                "size": 12,
                "sizeType": "unit",
                "parentId": 1704611159221,
                "pricePerOz": 1.12,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704654204623,
                "type": "Mason Jars (4oz)",
                "brand": "Ball",
                "cost": 7.97,
                "size": 4,
                "sizeType": "unit",
                "parentId": 1704611159221,
                "pricePerOz": 1.99,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704654240123,
                "type": "Mason Jar Lids (Regular)",
                "brand": "Ball",
                "cost": 3.47,
                "size": 12,
                "sizeType": "unit",
                "parentId": 1704611159221,
                "pricePerOz": 0.29,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704654280413,
                "type": "Mason Jar Lids (wood regular)",
                "brand": "Ball",
                "cost": 13.42,
                "size": 3,
                "sizeType": "unit",
                "parentId": 1704611159221,
                "pricePerOz": 4.47,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1706334184866,
                "type": "Organic Turkish Figs",
                "brand": "1",
                "cost": 7.99,
                "size": 10,
                "sizeType": "oz",
                "parentId": 1704611159221,
                "pricePerOz": 0.799,
                "dateAdded": "1/27/2024"
            },
            {
                "id": 1706416267195,
                "type": "Organic Milk (whole)",
                "brand": "Great Value",
                "cost": 6.98,
                "size": 128,
                "sizeType": "oz",
                "parentId": 1704611159221,
                "pricePerOz": 0.05453125,
                "dateAdded": "1/27/2024"
            }
        ],
        "pageSize": 10
    },
    {
        "id": 1704611173904,
        "name": "Giant Eagle",
        "address": "7400 Broadview Rd",
        "zip": 44134,
        "items": [
            {
                "id": 1704649225958,
                "type": "Organic Cabbage",
                "brand": "",
                "cost": 3.98,
                "size": 1,
                "sizeType": "unit",
                "parentId": 1704611173904,
                "pricePerOz": 3.98,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704649368730,
                "type": "Organic Red Pepper Flakes",
                "brand": "Simply Organic",
                "cost": 5.79,
                "size": 2.39,
                "sizeType": "oz",
                "parentId": 1704611173904,
                "pricePerOz": 2.42,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704649465720,
                "type": "Organic Chipotle Powder",
                "brand": "Simply Organic",
                "cost": 7.79,
                "size": 2.65,
                "sizeType": "oz",
                "parentId": 1704611173904,
                "pricePerOz": 2.94,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1706332000409,
                "type": "Organic Tomatoes (vine)",
                "brand": "",
                "cost": 3.99,
                "size": 1,
                "sizeType": "lb",
                "parentId": 1704611173904,
                "pricePerOz": 0.249375,
                "dateAdded": "1/27/2024"
            },
            {
                "id": 1706332022799,
                "type": "Organic Baby Bella Mushrooms",
                "brand": "Nature's Basket",
                "cost": 3.29,
                "size": 8,
                "sizeType": "oz",
                "parentId": 1704611173904,
                "pricePerOz": 0.41125,
                "dateAdded": "1/27/2024"
            },
            {
                "id": 1706332114584,
                "type": "Organic Chicken Breast (boneless)",
                "brand": "Bell & Evans",
                "cost": 11.99,
                "size": 1.5,
                "sizeType": "lb",
                "parentId": 1704611173904,
                "pricePerOz": 0.4995833333333333,
                "dateAdded": "1/27/2024"
            },
            {
                "id": 1706332159664,
                "type": "Organic Carrots",
                "brand": "Nature's Basket",
                "cost": 7.99,
                "size": 5,
                "sizeType": "lb",
                "parentId": 1704611173904,
                "pricePerOz": 0.099875,
                "dateAdded": "1/27/2024"
            },
            {
                "id": 1706332200663,
                "type": "Organic Romaine Lettuce Hearts",
                "brand": "",
                "cost": 4.49,
                "size": 3,
                "sizeType": "unit",
                "parentId": 1704611173904,
                "pricePerOz": 1.4966666666666668,
                "dateAdded": "1/27/2024"
            },
            {
                "id": 1706333075946,
                "type": "Organic Peanut Butter",
                "brand": "Nature's Basket",
                "cost": 4.29,
                "size": 16,
                "sizeType": "oz",
                "parentId": 1704611173904,
                "pricePerOz": 0.268125,
                "dateAdded": "1/27/2024"
            },
            {
                "id": 1706333125428,
                "type": "Organic Red Potatoes",
                "brand": "Nature's Basket",
                "cost": 5.29,
                "size": 3,
                "sizeType": "lb",
                "parentId": 1704611173904,
                "pricePerOz": 0.11020833333333334,
                "dateAdded": "1/27/2024"
            },
            {
                "id": 1706333151520,
                "type": "Organic Turkish Figs",
                "brand": "Nature's Basket",
                "cost": 7.49,
                "size": 10,
                "sizeType": "oz",
                "parentId": 1704611173904,
                "pricePerOz": 0.749,
                "dateAdded": "1/27/2024"
            },
            {
                "id": 1706333187595,
                "type": "Organic Honey",
                "brand": "Nature's Basket",
                "cost": 9.99,
                "size": 16,
                "sizeType": "oz",
                "parentId": 1704611173904,
                "pricePerOz": 0.624375,
                "dateAdded": "1/27/2024"
            },
            {
                "id": 1706333272144,
                "type": "Organic Whole Milk Yogurt (plain)",
                "brand": "Stonyfield Organic",
                "cost": 4.99,
                "size": 32,
                "sizeType": "oz",
                "parentId": 1704611173904,
                "pricePerOz": 0.1559375,
                "dateAdded": "1/27/2024"
            },
            {
                "id": 1706333309915,
                "type": "Organic Rice (Jasmine)",
                "brand": "Lundberg",
                "cost": 11.29,
                "size": 32,
                "sizeType": "oz",
                "parentId": 1704611173904,
                "pricePerOz": 0.3528125,
                "dateAdded": "1/27/2024"
            },
            {
                "id": 1706333336739,
                "type": "Organic Uncured Beef Hot Dogs",
                "brand": "Applegate",
                "cost": 8.29,
                "size": 10,
                "sizeType": "oz",
                "parentId": 1704611173904,
                "pricePerOz": 0.829,
                "dateAdded": "1/27/2024"
            },
            {
                "id": 1706333368501,
                "type": "Organic Garlic Powder",
                "brand": "Simply Organic",
                "cost": 6.99,
                "size": 3.64,
                "sizeType": "oz",
                "parentId": 1704611173904,
                "pricePerOz": 1.9203296703296704,
                "dateAdded": "1/27/2024"
            },
            {
                "id": 1706333412609,
                "type": "Organic Ghee",
                "brand": "Organic Valley",
                "cost": 9.99,
                "size": 7.5,
                "sizeType": "oz",
                "parentId": 1704611173904,
                "pricePerOz": 1.332,
                "dateAdded": "1/27/2024"
            },
            {
                "id": 1706333441178,
                "type": "Organic Chili Powder",
                "brand": "Simply Organic",
                "cost": 6.29,
                "size": 2.89,
                "sizeType": "oz",
                "parentId": 1704611173904,
                "pricePerOz": 2.176470588235294,
                "dateAdded": "1/27/2024"
            },
            {
                "id": 1706333469575,
                "type": "Organic Onion Powder",
                "brand": "Simply Organic",
                "cost": 6.29,
                "size": 3,
                "sizeType": "oz",
                "parentId": 1704611173904,
                "pricePerOz": 2.0966666666666667,
                "dateAdded": "1/27/2024"
            },
            {
                "id": 1706333492437,
                "type": "Organic Paprika",
                "brand": "Simply Organic",
                "cost": 6.29,
                "size": 2.96,
                "sizeType": "oz",
                "parentId": 1704611173904,
                "pricePerOz": 2.125,
                "dateAdded": "1/27/2024"
            },
            {
                "id": 1706333628892,
                "type": "Organic Cane Sugar",
                "brand": "Domino",
                "cost": 5.09,
                "size": 1.5,
                "sizeType": "lb",
                "parentId": 1704611173904,
                "pricePerOz": 0.21208333333333332,
                "dateAdded": "1/27/2024"
            },
            {
                "id": 1706333649685,
                "type": "Organic Curry Powder",
                "brand": "Simply Organic",
                "cost": 6.49,
                "size": 3,
                "sizeType": "oz",
                "parentId": 1704611173904,
                "pricePerOz": 2.1633333333333336,
                "dateAdded": "1/27/2024"
            },
            {
                "id": 1706333682603,
                "type": "Organic Bay Laurel Leaves",
                "brand": "Simply Organic",
                "cost": 8.29,
                "size": 0.14,
                "sizeType": "oz",
                "parentId": 1704611173904,
                "pricePerOz": 59.2142857142857,
                "dateAdded": "1/27/2024"
            }
        ],
        "pageSize": 10
    },
    {
        "id": 1704611181025,
        "name": "Amazon",
        "address": "amazon.com",
        "zip": "",
        "items": [
            {
                "id": 1704649584381,
                "type": "Organic Pine Nuts",
                "brand": "Essential Spice",
                "cost": 21.64,
                "size": 1,
                "sizeType": "lb",
                "parentId": 1704611181025,
                "pricePerOz": 1.35,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704649669384,
                "type": "Corn Starch",
                "brand": "Anthony's",
                "cost": 16.99,
                "size": 2,
                "sizeType": "lb",
                "parentId": 1704611181025,
                "pricePerOz": 0.53,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704649701683,
                "type": "Organic Corn Starch",
                "brand": "Let's Do Organic",
                "cost": 7.99,
                "size": 6,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 1.33,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704649769025,
                "type": "Organic Soy Sauce (low sodium)",
                "brand": "Soeos",
                "cost": 8.5,
                "size": 16.2,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 0.52,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704649833928,
                "type": "Organic Italian Seasoning",
                "brand": "Simply Organic",
                "cost": 4.79,
                "size": 0.95,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 5.04,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704649986746,
                "type": "Organic Italian Seasoning",
                "brand": "Frontier Co-op",
                "cost": 18.29,
                "size": 1,
                "sizeType": "lb",
                "parentId": 1704611181025,
                "pricePerOz": 1.14,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704650021175,
                "type": "Organic Paprika",
                "brand": "Simply Organic",
                "cost": 4.79,
                "size": 2.96,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 1.62,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704650051945,
                "type": "Organic Paprika",
                "brand": "Watkins",
                "cost": 4.59,
                "size": 2.9,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 1.58,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704650076194,
                "type": "Organic Paprika",
                "brand": "HQOExpress",
                "cost": 18.99,
                "size": 1.12,
                "sizeType": "lb",
                "parentId": 1704611181025,
                "pricePerOz": 1.06,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704650139719,
                "type": "Organic Onion Powder",
                "brand": "Watkins",
                "cost": 13.49,
                "size": 22,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 0.61,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704650284293,
                "type": "Organic Onion Powder",
                "brand": "Simply Organic",
                "cost": 4.99,
                "size": 3,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 1.66,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704650353717,
                "type": "Organic Cayenne Pepper (ground)",
                "brand": "Watkins",
                "cost": 19.49,
                "size": 16.5,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 1.18,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704650400298,
                "type": "Organic Cayenne Pepper (ground)",
                "brand": "Frontier Co-op",
                "cost": 16,
                "size": 1,
                "sizeType": "lb",
                "parentId": 1704611181025,
                "pricePerOz": 1,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704650563132,
                "type": "Organic Chili Powder",
                "brand": "Watkins",
                "cost": 14.99,
                "size": 16.1,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 0.93,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704650635184,
                "type": "Organic Red Pepper Flakes",
                "brand": "Jiva",
                "cost": 4.99,
                "size": 7,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 0.71,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704650952332,
                "type": "Organic Ceylon Cinnamon Sticks",
                "brand": "Jiva",
                "cost": 13.98,
                "size": 7,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 2,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704651137424,
                "type": "Organic Cumin Powder",
                "brand": "Anthony's",
                "cost": 14.99,
                "size": 1,
                "sizeType": "lb",
                "parentId": 1704611181025,
                "pricePerOz": 0.94,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704651176035,
                "type": "Organic Cumin Seed",
                "brand": "Starwest Botanicals",
                "cost": 21.58,
                "size": 1,
                "sizeType": "lb",
                "parentId": 1704611181025,
                "pricePerOz": 1.35,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704651216861,
                "type": "Organic Cumin Seed (black)",
                "brand": "Jiva",
                "cost": 9.99,
                "size": 16,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 0.62,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704651292846,
                "type": "Organic Whole Cloves (ceylon)",
                "brand": "True Organic",
                "cost": 14.75,
                "size": 16,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 0.92,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704653064424,
                "type": "Organic Bay Laurel Leaves",
                "brand": "Chiron Kentauros",
                "cost": 8.9,
                "size": 20,
                "sizeType": "g",
                "parentId": 1704611181025,
                "pricePerOz": 12.62,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704653130269,
                "type": "Organic Flax Seeds",
                "brand": "Yupik",
                "cost": 10.99,
                "size": 2.2,
                "sizeType": "lb",
                "parentId": 1704611181025,
                "pricePerOz": 0.31,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704653215075,
                "type": "Organic White Peppercorns (ceylon)",
                "brand": "True Organic",
                "cost": 14.75,
                "size": 16,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 0.92,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704653270338,
                "type": "Organic Vanilla Bean (madagascar)",
                "brand": "Fitnclean",
                "cost": 18.99,
                "size": 26,
                "sizeType": "unit",
                "parentId": 1704611181025,
                "pricePerOz": 0.73,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704653321394,
                "type": "Organic Coriander Seeds",
                "brand": "Anthony's",
                "cost": 12.99,
                "size": 1,
                "sizeType": "lb",
                "parentId": 1704611181025,
                "pricePerOz": 0.81,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704653366407,
                "type": "Organic Anise Seeds",
                "brand": "J Mac Botanicals",
                "cost": 19.99,
                "size": 16,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 1.25,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704653397980,
                "type": "Organic Fennel Seeds",
                "brand": "Anthony's",
                "cost": 14.99,
                "size": 1.5,
                "sizeType": "lb",
                "parentId": 1704611181025,
                "pricePerOz": 0.62,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704653446295,
                "type": "Organic Rosemary",
                "brand": "Monterey Bar Herb Co",
                "cost": 12.99,
                "size": 1,
                "sizeType": "lb",
                "parentId": 1704611181025,
                "pricePerOz": 0.81,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704653526395,
                "type": "Organic Thyme",
                "brand": "Anthony's",
                "cost": 11.79,
                "size": 8,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 1.47,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704653548155,
                "type": "Organic Basil",
                "brand": "Anthony's",
                "cost": 11.49,
                "size": 8,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 1.44,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704653582984,
                "type": "Organic Oregano",
                "brand": "Watkins",
                "cost": 10.58,
                "size": 4.6,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 2.3,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704653610736,
                "type": "Organic Oregano",
                "brand": "Frontier Co-op",
                "cost": 18.07,
                "size": 1,
                "sizeType": "lb",
                "parentId": 1704611181025,
                "pricePerOz": 1.13,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704653643045,
                "type": "Organic Parsley",
                "brand": "Watkins",
                "cost": 14.49,
                "size": 4.7,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 3.08,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704653673525,
                "type": "Organic Dill",
                "brand": "Frontier",
                "cost": 32.49,
                "size": 1,
                "sizeType": "lb",
                "parentId": 1704611181025,
                "pricePerOz": 2.03,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704653694046,
                "type": "Organic Dill",
                "brand": "Simply Organic",
                "cost": 5.09,
                "size": 0.81,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 6.28,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704653752308,
                "type": "Organic Lavender",
                "brand": "Anthony's",
                "cost": 10.99,
                "size": 5,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 2.2,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704653806410,
                "type": "Mason Jars (16oz)",
                "brand": "Ball",
                "cost": 18.99,
                "size": 4,
                "sizeType": "unit",
                "parentId": 1704611181025,
                "pricePerOz": 4.75,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704653863963,
                "type": "Mason Jars (16oz)",
                "brand": "Ball",
                "cost": 23.99,
                "size": 6,
                "sizeType": "unit",
                "parentId": 1704611181025,
                "pricePerOz": 4,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704653885970,
                "type": "Mason Jars (32oz)",
                "brand": "Ball",
                "cost": 9.99,
                "size": 2,
                "sizeType": "unit",
                "parentId": 1704611181025,
                "pricePerOz": 5,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704653906359,
                "type": "Mason Jars (64oz wide)",
                "brand": "Ball",
                "cost": 15.23,
                "size": 2,
                "sizeType": "unit",
                "parentId": 1704611181025,
                "pricePerOz": 7.62,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704653930609,
                "type": "Mason Jars (32oz wide)",
                "brand": "Ball",
                "cost": 12,
                "size": 2,
                "sizeType": "unit",
                "parentId": 1704611181025,
                "pricePerOz": 6,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704653981323,
                "type": "Mason Jar Lids (wood)",
                "brand": "Ball",
                "cost": 12.99,
                "size": 5,
                "sizeType": "unit",
                "parentId": 1704611181025,
                "pricePerOz": 2.6,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1704654007911,
                "type": "Mason Jar Lids (wide)",
                "brand": "Ball",
                "cost": 16.99,
                "size": 5,
                "sizeType": "unit",
                "parentId": 1704611181025,
                "pricePerOz": 3.4,
                "dateAdded": "1/7/2024"
            },
            {
                "id": 1705028129500,
                "type": "Organic Worcestershire Sauce",
                "brand": "WanJaShan",
                "cost": 16,
                "size": 20,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 0.8,
                "dateAdded": "1/11/2024"
            },
            {
                "id": 1705028234815,
                "type": "Organic Star Anise (whole)",
                "brand": "Frontier Co-op",
                "cost": 30.46,
                "size": 1,
                "sizeType": "lb",
                "parentId": 1704611181025,
                "pricePerOz": 1.90375,
                "dateAdded": "1/11/2024"
            },
            {
                "id": 1705028271452,
                "type": "Organic Soy Suace",
                "brand": "Kikkoman",
                "cost": 12.67,
                "size": 25.4,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 0.4988188976377953,
                "dateAdded": "1/11/2024"
            },
            {
                "id": 1705028286322,
                "type": "Organic Sage",
                "brand": "J Mac Botanicals",
                "cost": 7.99,
                "size": 2,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 3.995,
                "dateAdded": "1/11/2024"
            },
            {
                "id": 1705028315167,
                "type": "Organic Garlic Powder",
                "brand": "McCormick",
                "cost": 13.36,
                "size": 16.75,
                "sizeType": "oz",
                "parentId": 1704611181025,
                "pricePerOz": 0.7976119402985075,
                "dateAdded": "1/11/2024"
            }
        ],
        "pageSize": 10
    },
    {
        "id": 1704775891535,
        "name": "Meijer",
        "address": "7701 Broadview Rd",
        "zip": "44131",
        "items": [
            {
                "id": 1704775934139,
                "type": "Organic Bananas",
                "brand": "",
                "cost": 0.69,
                "size": 1,
                "sizeType": "lb",
                "parentId": 1704775891535,
                "pricePerOz": 0.043125,
                "dateAdded": "1/8/2024"
            },
            {
                "id": 1704775960271,
                "type": "Organic Romaine Lettuce Hearts",
                "brand": "Earthbound Farms",
                "cost": 3.69,
                "size": 7,
                "sizeType": "oz",
                "parentId": 1704775891535,
                "pricePerOz": 0.5271428571428571,
                "dateAdded": "1/8/2024"
            },
            {
                "id": 1704775988474,
                "type": "Organic Green Grapes",
                "brand": "",
                "cost": 4.29,
                "size": 1,
                "sizeType": "lb",
                "parentId": 1704775891535,
                "pricePerOz": 0.268125,
                "dateAdded": "1/8/2024"
            },
            {
                "id": 1704776015437,
                "type": "Organic Avocados",
                "brand": "Mission",
                "cost": 4.49,
                "size": 4,
                "sizeType": "unit",
                "parentId": 1704775891535,
                "pricePerOz": 1.1225,
                "dateAdded": "1/8/2024"
            },
            {
                "id": 1704776036213,
                "type": "Organic Clementines",
                "brand": "",
                "cost": 4.99,
                "size": 2,
                "sizeType": "lb",
                "parentId": 1704775891535,
                "pricePerOz": 0.1559375,
                "dateAdded": "1/8/2024"
            },
            {
                "id": 1704776081188,
                "type": "Organic Chicken Breast (boneless)",
                "brand": "True Goodness",
                "cost": 7.99,
                "size": 1,
                "sizeType": "lb",
                "parentId": 1704775891535,
                "pricePerOz": 0.499375,
                "dateAdded": "1/8/2024"
            },
            {
                "id": 1704778639506,
                "type": "Organic Spinach",
                "brand": "Earthbound Farms",
                "cost": 3.69,
                "size": 5,
                "sizeType": "oz",
                "parentId": 1704775891535,
                "pricePerOz": 0.738,
                "dateAdded": "1/9/2024"
            },
            {
                "id": 1704778668457,
                "type": "Organic Grass-Fed Ground Beef 85/15",
                "brand": "True Goodness",
                "cost": 19.99,
                "size": 3,
                "sizeType": "lb",
                "parentId": 1704775891535,
                "pricePerOz": 0.4164583333333333,
                "dateAdded": "1/9/2024"
            },
            {
                "id": 1704778768687,
                "type": "Organic Celery",
                "brand": "",
                "cost": 2.49,
                "size": 1,
                "sizeType": "unit",
                "parentId": 1704775891535,
                "pricePerOz": 2.49,
                "dateAdded": "1/9/2024"
            },
            {
                "id": 1704778798261,
                "type": "Organic Russet Potatoes",
                "brand": "",
                "cost": 5.49,
                "size": 3,
                "sizeType": "lb",
                "parentId": 1704775891535,
                "pricePerOz": 0.114375,
                "dateAdded": "1/9/2024"
            },
            {
                "id": 1704778830742,
                "type": "Organic Eggs (free range)",
                "brand": "Happy Egg",
                "cost": 5.29,
                "size": 12,
                "sizeType": "unit",
                "parentId": 1704775891535,
                "pricePerOz": 0.44083333333333335,
                "dateAdded": "1/9/2024"
            },
            {
                "id": 1704778861115,
                "type": "Organic Onion",
                "brand": "",
                "cost": 3.99,
                "size": 3,
                "sizeType": "lb",
                "parentId": 1704775891535,
                "pricePerOz": 0.083125,
                "dateAdded": "1/9/2024"
            },
            {
                "id": 1704778909445,
                "type": "Organic Limes",
                "brand": "",
                "cost": 2.99,
                "size": 1,
                "sizeType": "lb",
                "parentId": 1704775891535,
                "pricePerOz": 0.186875,
                "dateAdded": "1/9/2024"
            },
            {
                "id": 1704778928358,
                "type": "Organic Whole Milk Yogurt (plain)",
                "brand": "Stonyfield Organic",
                "cost": 4.79,
                "size": 32,
                "sizeType": "oz",
                "parentId": 1704775891535,
                "pricePerOz": 0.1496875,
                "dateAdded": "1/9/2024"
            },
            {
                "id": 1704779074245,
                "type": "Organic Eggs",
                "brand": "True Goodness",
                "cost": 5.19,
                "size": 12,
                "sizeType": "unit",
                "parentId": 1704775891535,
                "pricePerOz": 0.43250000000000005,
                "dateAdded": "1/9/2024"
            },
            {
                "id": 1704779088509,
                "type": "Organic Green Onion",
                "brand": "",
                "cost": 1.89,
                "size": 1,
                "sizeType": "unit",
                "parentId": 1704775891535,
                "pricePerOz": 1.89,
                "dateAdded": "1/9/2024"
            },
            {
                "id": 1704779134226,
                "type": "Organic EVOO",
                "brand": "O-live & Co",
                "cost": 17.99,
                "size": 33,
                "sizeType": "oz",
                "parentId": 1704775891535,
                "pricePerOz": 0.545151515151515,
                "dateAdded": "1/9/2024"
            },
            {
                "id": 1706419117675,
                "type": "Organic Milk (whole)",
                "brand": "True Goodness",
                "cost": 6.49,
                "size": 1,
                "sizeType": "gal",
                "parentId": 1704775891535,
                "pricePerOz": 0.050703125,
                "dateAdded": "1/28/2024"
            }
        ],
        "pageSize": 10
    }
]

export default defaultGroceryStores;